import { BONUS, FIAT } from 'constants/currency';

import React, { FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import {
    GridCellParams, GridColumns, GridRenderCellParams, GridValueGetterParams,
} from '@mui/x-data-grid-premium';
import { PriceRateT } from 'types/PriceRate';
import { PriceSalesRoundT } from 'types/PriceSalesRound';

type BoolIconT = 'cancellable' | 'transferable';
type CellT = 'saleRate' | 'minRate' | 'returnRate' | 'shareOfPaymentBonus' | 'shareOfPaymentFiat' | 'transferDisabledBeforeHours';

type HeaderDataT<T> = {
    params: GridCellParams;
    property: T;
    id: string;
}

const RenderBoolIcon: FC<HeaderDataT<BoolIconT>> = ({ params, property, id }) => {
    const priceRate = params.row.priceRates.find((item: PriceRateT) => item.priceSalesRoundId === id);

    if (!priceRate) {
        return <> </>;
    }

    return priceRate[property] ? <DoneIcon color="success" /> : <CloseIcon color="error" />;
};

const renderCell = ({ params, property, id }: HeaderDataT<CellT>): string | null => {
    const priceRate = params.row.priceRates.find((item: PriceRateT) => item.priceSalesRoundId === id);

    return priceRate ? priceRate[property] : null;
};

export const setHeaderData = (headerData: GridColumns, priceSalesRound: PriceSalesRoundT, index: number) => {
    headerData.push(
        {
            field: `saleRate-${index}`,
            headerName: 'Тариф продажи',
            align: 'center',
            headerAlign: 'center',
            width: 150,
            type: 'number',
            headerClassName: 'data-grid-sale-column',
            cellClassName: 'data-grid-sale-column',
            valueGetter: (params: GridValueGetterParams) => renderCell({ params, property: 'saleRate', id: priceSalesRound.id }),
        },
        {
            field: `minRate-${index}`,
            headerName: 'Минимальный тариф',
            align: 'center',
            headerAlign: 'center',
            width: 200,
            type: 'number',
            headerClassName: 'data-grid-sale-column',
            cellClassName: 'data-grid-sale-column',
            valueGetter: (params: GridValueGetterParams) => renderCell({ params, property: 'minRate', id: priceSalesRound.id }),
        },
        {
            field: `forwardTransferCost/backTransferCost-${index}`,
            headerName: 'Стоимость переноса назад/вперед',
            align: 'center',
            headerAlign: 'center',
            width: 300,
            type: 'number',
            headerClassName: 'data-grid-sale-column',
            cellClassName: 'data-grid-sale-column',
            valueGetter: (params: GridValueGetterParams) => {
                const priceRate = params.row.priceRates.find((item: PriceRateT) => item.priceSalesRoundId === priceSalesRound.id);

                if (!priceRate) {
                    return null;
                }

                return `${priceRate.backTransferCost} / ${priceRate.forwardTransferCost}`;
            },
        },
        {
            field: `performerSaleIncomeValue-${index}`,
            headerName: 'Доля/сумма исполнителя при продаже',
            align: 'center',
            headerAlign: 'center',
            width: 300,
            type: 'number',
            headerClassName: 'data-grid-sale-column',
            cellClassName: 'data-grid-sale-column',
            valueGetter: (params: GridValueGetterParams) => {
                const priceRate = params.row.priceRates.find((item: PriceRateT) => item.priceSalesRoundId === priceSalesRound.id);

                if (!priceRate) {
                    return null;
                }

                return `${priceRate.performerSaleIncomeValue} ${priceRate.performerSaleIncomeCurrency}`;
            },
        },
        {
            field: `shareOfPaymentFiat-${index}`,
            headerName: FIAT,
            align: 'center',
            headerAlign: 'center',
            width: 100,
            type: 'number',
            headerClassName: 'data-grid-sale-column',
            cellClassName: 'data-grid-sale-column',
            valueGetter: (params: GridValueGetterParams) => renderCell({ params, property: 'shareOfPaymentFiat', id: priceSalesRound.id }),
        },
        {
            field: `shareOfPaymentBonus-${index}`,
            headerName: BONUS,
            align: 'center',
            headerAlign: 'center',
            width: 100,
            type: 'number',
            headerClassName: 'data-grid-sale-column',
            cellClassName: 'data-grid-sale-column',
            valueGetter: (params: GridValueGetterParams) => renderCell({ params, property: 'shareOfPaymentBonus', id: priceSalesRound.id }),
        },
        {
            field: `transferable-${index}`,
            headerName: 'Перенос',
            align: 'center',
            headerAlign: 'center',
            width: 100,
            type: 'boolean',
            headerClassName: 'data-grid-cancel-column',
            cellClassName: 'data-grid-cancel-column',
            renderCell: (params: GridRenderCellParams) => <RenderBoolIcon params={ params } property="transferable" id={ priceSalesRound.id } />,
        },
        {
            field: `transferDisabledBeforeHours-${index}`,
            headerName: 'Перенос недоступен за N часов',
            width: 230,
            align: 'center',
            headerAlign: 'center',
            type: 'number',
            headerClassName: 'data-grid-cancel-column',
            cellClassName: 'data-grid-cancel-column',
            valueGetter: (params: GridValueGetterParams) => renderCell({ params, property: 'transferDisabledBeforeHours', id: priceSalesRound.id }),
        },
        {
            field: `cancellable-${index}`,
            headerName: 'Отмена',
            align: 'center',
            headerAlign: 'center',
            width: 100,
            headerClassName: 'data-grid-cancel-column',
            cellClassName: 'data-grid-cancel-column',
            renderCell: (params: GridRenderCellParams) => <RenderBoolIcon params={ params } property="cancellable" id={ priceSalesRound.id } />,
        },
        {
            field: `returnRate-${index}`,
            headerName: 'Тариф возврата',
            align: 'center',
            headerAlign: 'center',
            width: 150,
            type: 'number',
            headerClassName: 'data-grid-cancel-column',
            cellClassName: 'data-grid-cancel-column',
            valueGetter: (params: GridValueGetterParams) => renderCell({ params, property: 'returnRate', id: priceSalesRound.id }),
        },
        {
            field: `performerReturnIncomeValue-${index}`,
            headerName: 'Доля/сумма исполнителя при возврате',
            align: 'center',
            headerAlign: 'center',
            width: 300,
            type: 'number',
            headerClassName: 'data-grid-cancel-column',
            cellClassName: 'border-right data-grid-cancel-column',
            valueGetter: (params: GridValueGetterParams) => {
                const priceRate = params.row.priceRates.find((item: PriceRateT) => item.priceSalesRoundId === priceSalesRound.id);

                if (!priceRate) {
                    return null;
                }

                return `${priceRate.performerReturnIncomeValue} ${priceRate.performerReturnIncomeCurrency}`;
            },
        },
    );
};
